import React, { useEffect, useState } from "react";
import DiscoverLuxHeader from "../../components/DiscoverLux/Header";
import { createImage } from "../../utils";
import News from "../../components/FilmFestival/News";
import Layout from "../../components/Layout";
import { apiRoutes } from "../../constants/apiRoutes";
import axios from "axios";
// import { Helmet } from "react-helmet";
import Helmet from "../../components/Helmet";
import routes from "../../components/constants/routes";

const DLNews = ({ location }) => {
  const [festivalNews, setFestivalNews] = useState([]);
  const [header, setHeader] = useState(undefined);
  const params = new URLSearchParams(location.search);
  const blogId = params.get("id");

  const fetchFilmNews = async () => {
    try {
      const { data } = await axios.get(
        apiRoutes.news(false, null, "discover luxembourg")
      );
      if (data) {
        setFestivalNews(data.data);
      }
    } catch (error) {}
  };

  const fetchHeader = async () => {
    try {
      const { data } = await axios.get(apiRoutes.header);
      if (data) {
        setHeader(data.data.attributes);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchHeader();
    fetchFilmNews();
  }, []);

  const seoData = {
    title: "News | Discover Luxembourg",
    description:
      "Explore the beauty and culture of Luxembourg through our news tab. Discover Luxembourg's hidden treasures, travel tips, local insights, and more. Your gateway to all things Luxembourg awaits!",
    url: `${process.env.GATSBY_SITE_URL}${routes.DLNews}/`,
  };

  return (
    <Layout>
      <>
        <Helmet
          title={seoData.title}
          description={seoData.description}
          url={seoData?.url}
        />

        <div className="discover-luxembourg">
          <div className="container-fluid p-mob">
            <div className="row">
              <div className="col-md-12">
                <div className="hero-section">
                  <img
                    src={createImage(
                      header?.discover_luxembourg_image?.data?.attributes?.url
                    )}
                    alt="The Discover Luxembourg "
                    width={"auto"}
                    height={"auto"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="head-tab mt-40">
                  <DiscoverLuxHeader />
                  <div className="tab-content" id="nav-tabContent">
                    <News data={festivalNews} blogId={blogId} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default DLNews;
