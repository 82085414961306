import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  appendImage,
  createImage,
  createMarkup,
  formatDateOnly,
} from "../../utils";
import { navigate } from "gatsby";
import routes from "../constants/routes";
import { isMobile } from "react-device-detect";
import { Link } from "gatsby";

const News = ({ data, blogId }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [selectedNews, setSelectedNews] = useState({});
  const [isDivVisible, setDivVisibility] = useState(true);
  const [activeBlogId, setActiveBlogId] = useState(undefined);

  const scrollContainerRef = useRef(null);
  const scrollMobContainerRef = useRef(null);

  const selectedNewsContent = selectedNews?.attributes;
  const isDL =
    selectedNewsContent?.type.data.attributes.newstype ===
    "discover luxembourg";

  const showAtTop = (index, datta = []) => {
    const temp = [...datta];
    if (index) {
      const tempIndex = temp.findIndex((el) => el.id == index);
      const sliced = temp.splice(tempIndex, 1);
      if (sliced.length) temp.unshift(sliced[0]);
      setFilteredData(temp);
    } else {
      setFilteredData(datta);
    }
  };

  useEffect(() => {
    if (blogId) {
      setActiveBlogId(blogId);
    }
  }, [blogId]);

  useMemo(() => {
    if (data.length) {
      console.log(activeBlogId, "sdfsdf");
      const activeNews = data.find((el) => el.id == activeBlogId);
      if (activeNews) setSelectedNews(activeNews);
      else setSelectedNews(data[0]);
      // setFilteredData(data);
      showAtTop(activeBlogId, data);
    }
  }, [data, activeBlogId]);

  useEffect(() => {
    let counter = 0;
    const timer = setInterval(() => {
      const header = document.querySelector(".news-sidebar");
      counter++;
      if (header) {
        clearInterval(timer);
        window.addEventListener("scroll", function () {
          if (window.pageYOffset >= 350) {
            header.classList.add("header-fixed");
          } else {
            header.classList.remove("header-fixed");
          }
        });
      } else if (counter >= 100) {
        clearInterval(timer);
      }
    }, 100);
  }, []);

  const toggleDiv = () => {
    setDivVisibility(!isDivVisible);
  };

  useEffect(() => {
    appendImage("news-content");
  }, [selectedNews]);

  const scrollToTop = (ref) => {
    const containerRef = ref?.current;
    containerRef.scrollTo({
      top: 0, // Scroll to the leftmost position
      behavior: "smooth",
    });

    if (isMobile) {
      const container = document.querySelector(".toggle-div"); // Replace with your container's ID

      if (container.scrollTop === 0 && isDivVisible === false) {
        toggleDiv();
      }

      // Add a scroll event listener to the container
      container.addEventListener("scroll", function () {
        if (container.scrollTop === 0) {
          // When the user reaches the top of the container
          toggleDiv();
        }
      });
    }
  };

  return (
    <div className="film-news">
      {Object.values(selectedNews)?.length ? (
        <div className="news-tab" id="newstab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 mobile-resposnive">
                <ul
                  // className="nav nav-tabs flex-column"
                  className={`nav nav-tabs toggle-div ${
                    isDivVisible ? "visible" : "news-open"
                  }`}
                  ref={scrollMobContainerRef}
                  style={{ maxHeight: isDivVisible ? "132px" : "350px" }}
                  id="myTab"
                  role="tablist"
                >
                  {filteredData?.map((el, i) => {
                    const news = el.attributes;
                    return (
                      <li
                        className="nav-item"
                        role="presentation"
                        onClick={() => {
                          // showAtTop(i, filteredData);
                          setSelectedNews(el);
                          navigate(
                            `${isDL ? routes.DLNews : routes.FFNews}?id=${
                              el.id
                            }#newstab`
                          );
                          scrollToTop(scrollMobContainerRef);
                        }}
                      >
                        <Link
                          href="#newstab"
                          className={`nav-link ${
                            selectedNews?.id === el.id ? "active" : ""
                          }`}
                        >
                          <div className="news-tab-list">
                            <img
                              src={createImage(
                                news?.image?.data?.attributes?.url
                              )}
                              alt={news?.image_alt_text}
                              width={"auto"}
                              height={"auto"}
                            />
                            <div>
                              <h6 className="mt-2">{news?.title}</h6>
                              <p>{formatDateOnly(news?.date)}</p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                <span className="arrow-toggle" onClick={toggleDiv}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path d="M480-200 240-440l56-56 184 183 184-183 56 56-240 240Zm0-240L240-680l56-56 184 183 184-183 56 56-240 240Z" />
                  </svg>
                </span>
              </div>
              <div className="col-md-4 col-lg-2 desk-resposnive">
                <div className="news-sidebar">
                  <div className="news-overflow" ref={scrollContainerRef}>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      {filteredData?.map((el, i) => {
                        const news = el.attributes;

                        return (
                          <li
                            className="nav-item"
                            role="presentation"
                            onClick={() => {
                              // showAtTop(i, filteredData);
                              setSelectedNews(el);
                              navigate(
                                `${isDL ? routes.DLNews : routes.FFNews}?id=${
                                  el.id
                                }#newstab`
                              );
                              scrollToTop(scrollContainerRef);
                            }}
                          >
                            <Link
                              href="#newstab"
                              className={`nav-link ${
                                selectedNews?.id === el.id ? "active" : ""
                              }`}
                            >
                              <div className="news-tab-list">
                                <img
                                  src={createImage(
                                    news?.image?.data?.attributes?.url
                                  )}
                                  alt={news?.image_alt_text}
                                  width={"auto"}
                                  height={"auto"}
                                />
                                <div>
                                  <h6>{news?.title}</h6>
                                  <p>{formatDateOnly(news?.date)}</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-lg-10">
                <div className="tab-content" id="myTabContent">
                  <div
                    // className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="news-tab-body">
                      <h2>{selectedNewsContent?.title}</h2>
                      <p className="date">
                        {selectedNewsContent?.date
                          ? formatDateOnly(selectedNewsContent?.date)
                          : ""}
                      </p>
                      <img
                        src={createImage(
                          selectedNewsContent?.image?.data?.attributes?.url
                        )}
                        alt={selectedNewsContent?.image_alt_text}
                        width={"auto"}
                        height={"auto"}
                      />
                      <div
                        className="news-content"
                        dangerouslySetInnerHTML={createMarkup(
                          selectedNewsContent?.description
                        )}
                      ></div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    ...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p className="text-center" style={{ margin: "15em 0" }}></p>
      )}
    </div>
  );
};

export default News;
